import { createUseStyles } from 'react-jss';
import { KowieDesignTheme, typography } from '../../Config/KowieDesignTheme';
import { images } from '../../Content/Images';

export const WfdMain = () => {
    const style = useStyles();

    return (
        <div className={style.container}>
            <div className={style.title}>{"What's for Din?"}</div>
            <div className={style.description}>
                Meal planning made simple. Categorize your own recipes or meals, then drag & drop them onto the
                calendar. That&apos;s it, keep it simple.
            </div>
            <div>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.kowiedesign.whatsfordin"
                >
                    <img alt="Get it on Google Play" src={images.googlePlayBadge} className={style.appStoreBadge} />
                </a>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/us/app/whats-for-din/id6446185017"
                >
                    <img alt="Download on the App Store" src={images.appStoreBadge} className={style.appStoreBadge} />
                </a>
            </div>
            <img alt="What's for Din?" src={images.wfdPhone} className={style.phone} />
        </div>
    );
};

const useStyles = createUseStyles((theme: KowieDesignTheme) => ({
    container: {
        width: '100vw',
        height: 'calc(100vh - 208px)',
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '3.5rem 10rem',
    },
    title: {
        ...typography.title1,
        ...typography.weight.bold,
        color: theme.text.primaryColor,
        display: 'flex',
        marginBottom: '4rem',
    },
    description: {
        ...typography.largeNormal,
        ...typography.weight.medium,
        color: theme.text.primaryColor,
        display: 'flex',
        marginBottom: '4rem',
        maxWidth: '36rem',
    },
    appStoreBadge: {
        height: '65px',
        paddingRight: '2rem',
    },
    phone: {
        position: 'relative',
        top: '-170px',
        left: '240px',
        pointerEvents: 'none',
    },
}));
