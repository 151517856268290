import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'theming';
import { theme } from './Config/KowieDesignTheme';
import './index.css';
import { Main } from './Routes/Main';
import { PrivacyPolicy } from './Routes/Shared/PrivacyPolicy';
import { TermsOfService } from './Routes/Shared/TermsOfService';
import { WfdMain } from './Routes/WhatsForDin/WfdMain';

const router = createBrowserRouter([
    {
        path: '/',
        element: <Main />,
        children: [
            {
                path: '',
                element: <WfdMain />,
            },
            {
                path: 'wfd',
                element: <WfdMain />,
            },
            {
                path: 'tos',
                element: <TermsOfService />,
            },
            {
                path: 'privacy',
                element: <PrivacyPolicy />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
        </ThemeProvider>
    </React.StrictMode>,
);
