export const colorPalette = {
    pureBlack: '#000000',
    white: '#FFFFFF',

    stockInkLighter: '#72777A',
    stockInkLight: '#6C7072',
    stockInkBase: '#404446',
    stockInkDark: '#303437',
    stockInkDarker: '#202325',
    stockInkDarkest: '#090A0A',
    stockInkDarkest50: '#090A0A80',

    stockSkyLightest: '#F7F9FA',
    stockSkyLighter: '#F2F4F5',
    stockSkyLight: '#E3E5E5',
    stockSkyBase: '#CDCFD0',
    stockSkyDark: '#979C9E',

    stockPurpleLightest: '#E7E7FF',
    stockPurpleLighter: '#C6C4FF',
    stockPurpleLight: '#9990FF',
    stockPurpleBase: '#6B4EFF',
    stockPurpleDarkest: '#5538EE',

    stockRedLightest: '#FFE5E5',
    stockRedLighter: '#FF9898',
    stockRedLight: '#FF6D6D',
    stockRedBase: '#FF5247',
    stockRedDarkest: '#D3180C',

    stockGreenLightest: '#ECFCE5',
    stockGreenLighter: '#7DDE86',
    stockGreenLight: '#4CD471',
    stockGreenBase: '#23C16B',
    stockGreenDarkest: '#198155',

    stockYellowLightest: '#FFEFD7',
    stockYellowLighter: '#FFD188',
    stockYellowLight: '#FFC462',
    stockYellowBase: '#FFB323',
    stockYellowDarkest: '#A05E03',

    stockBlueLightest: '#C9F0FF',
    stockBlueLighter: '#9BDCFD',
    stockBlueLight: '#6EC2FB',
    stockBlueBase: '#48A7F8',
    stockBlueDarkest: '#0065D0',

    red0: '#FFF9F9',
    red1: '#FEE6E6',
    red2: '#FCB3B2',
    red3: '#FA7D7A',
    red4: '#FA4C47',
    red5: '#D92419',
    red6: '#AB1A12',
    red7: '#7C1009',
    red8: '#570804',
    red9: '#360302',

    orange0: '#FFF9F4',
    orange1: '#FFE8D3',
    orange2: '#FFB849',
    orange3: '#D89600',
    orange4: '#BA8000',
    orange5: '#966700',
    orange6: '#754F00',
    orange7: '#543700',
    orange8: '#543700',
    orange9: '#221400',

    yellow0: '#FFFAEB',
    yellow1: '#FFEC9D',
    yellow2: '#E0C500',
    yellow3: '#B9A300',
    yellow4: '#9F8B00',
    yellow5: '#807000',
    yellow6: '#5E5800',
    yellow7: '#433E00',
    yellow8: '#2D2A00',
    yellow9: '#1A1700',

    lemonLime0: '#F3FFDE',
    lemonLime1: '#D0FC41',
    lemonLime2: '#AED335',
    lemonLime3: '#8FAE2A',
    lemonLime4: '#7B9522',
    lemonLime5: '#62781A',
    lemonLime6: '#4C5D12',
    lemonLime7: '#35420A',
    lemonLime8: '#232C05',
    lemonLime9: '#131902',

    green0: '#EEFFEE',
    green1: '#B1FEB0',
    green2: '#7BD675',
    green3: '#2CBB23',
    green4: '#25A11D',
    green5: '#1B8115',
    green6: '#13650E',
    green7: '#0B4707',
    green8: '#053103',
    green9: '#021C01',

    teal0: '#EEFDFE',
    teal1: '#B1F8FD',
    teal2: '#4CD8DF',
    teal3: '#3DB2B8',
    teal4: '#33999E',
    teal5: '#287B7F',
    teal6: '#1D5F63',
    teal7: '#124446',
    teal8: '#092E2F',
    teal9: '#041A1B',

    blue0: '#F8FAFF',
    blue1: '#E2ECFE',
    blue2: '#A4C8FC',
    blue3: '#55A7FA',
    blue4: '#2490E5',
    blue5: '#1B73B9',
    blue6: '#135A92',
    blue7: '#0A3F69',
    blue8: '#052A49',
    blue9: '#02182C',

    purple0: '#FBF9FF',
    purple1: '#EEE9FF',
    purple2: '#CDBCFF',
    purple3: '#B090FF',
    purple4: '#9E6FFF',
    purple5: '#8B3EFF',
    purple6: '#7400E6',
    purple7: '#5300A8',
    purple8: '#390077',
    purple9: '#21004B',

    maroon0: '#FEF8FE',
    maroon1: '#FDE4FB',
    maroon2: '#F9ACF3',
    maroon3: '#F56CED',
    maroon4: '#EC36E3',
    maroon5: '#BF2AB7',
    maroon6: '#961F90',
    maroon7: '#6C1367',
    maroon8: '#4B0A48',
    maroon9: '#2E042C',

    gray0: '#FAFAFA',
    gray1: '#EBECEC',
    gray2: '#C3C6C7',
    gray3: '#A1A3A4',
    gray4: '#8A8C8D',
    gray5: '#6E7071',
    gray6: '#565757',
    gray7: '#3C3D3D',
    gray8: '#282929',
    gray9: '#161717',
};

export const typography = {
    title1: {
        fontSize: '3rem',
        lineHeight: '3.5rem',
    },
    title2: {
        fontSize: '2rem',
        lineHeight: '2.25rem',
    },
    title3: {
        fontSize: '1.5rem',
        lineHeight: '2rem',
    },
    largeNone: {
        fontSize: '1.125rem',
        lineHeight: '1.125rem',
    },
    largeTight: {
        fontSize: '1.125rem',
        lineHeight: '1.25rem',
    },
    largeNormal: {
        fontSize: '1.125rem',
        lineHeight: '1.5rem',
    },
    regularNone: {
        fontSize: '1rem',
        lineHeight: '1rem',
    },
    regularTight: {
        fontSize: '1rem',
        lineHeight: '1.25rem',
    },
    regularNormal: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
    },
    smallNone: {
        fontSize: '.875rem',
        lineHeight: '.875rem',
    },
    smallTight: {
        fontSize: '.875rem',
        lineHeight: '1rem',
    },
    smallNormal: {
        fontSize: '.875rem',
        lineHeight: '1.25rem',
    },
    tinyNone: {
        fontSize: '.75rem',
        lineHeight: '.75rem',
    },
    tinyTight: {
        fontSize: '.75rem',
        lineHeight: '.875rem',
    },
    tinyNormal: {
        fontSize: '.75rem',
        lineHeight: '1rem',
    },
    weight: {
        bold: {
            fontWeight: '700',
        },
        medium: {
            fontWeight: '500',
        },
        regular: {
            fontWeight: '400',
        },
    },
};

export const theme: KowieDesignTheme = {
    background: {
        gradient: `linear-gradient(180deg, ${colorPalette.stockPurpleBase} 0%, #9C4EFF 100%)`,
        static: colorPalette.white,
    },
    text: {
        primaryColor: colorPalette.white,
    },
};

export interface KowieDesignTheme {
    background: {
        gradient: string;
        static: string;
    };
    text: {
        primaryColor: string;
    };
}
