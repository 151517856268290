import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { KowieDesignTheme, colorPalette, typography } from '../Config/KowieDesignTheme';
import { images } from '../Content/Images';

export const Header = () => {
    const style = useStyles();

    return (
        <div className={style.container}>
            <div className={style.appLabelContainer}>
                <span className={style.appLabel}>Apps</span>
            </div>
            <div className={style.linksContainer}>
                <div className={style.appsContainer}>
                    <Link className={style.link} to="/wfd">
                        <img src={images.icon} className={style.appIcon} />
                        {"What's for Din?"}
                    </Link>
                </div>
                <div className={style.policiesContainer}>
                    <Link className={style.policiesLink} to="/tos">
                        {'Terms of Service'}
                    </Link>
                    <Link className={style.policiesLink} to="/privacy">
                        {'Privacy Policy'}
                    </Link>
                    <Link
                        className={style.contactLink}
                        to="mailto:whatsfordin.contact@gmail.com?subject=What%27s%20For%20Din%3F"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={images.mail} className={style.mailIcon} />
                        {'whatsfordin.contact@gmail.com'}
                    </Link>
                </div>
            </div>
        </div>
    );
};

const useStyles = createUseStyles((theme: KowieDesignTheme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    appLabelContainer: {
        display: 'flex',
        width: '10rem',
        minWidth: '10rem',
    },
    appIcon: {
        width: '2rem',
        height: '2rem',
    },
    appLabel: {
        ...typography.largeNone,
        ...typography.weight.bold,
        color: theme.text.primaryColor,
        paddingLeft: '4rem',
    },
    linksContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    appsContainer: {
        paddingRight: '1rem',
    },
    link: {
        ...typography.title3,
        ...typography.weight.bold,
        display: 'flex',
        color: theme.text.primaryColor,
        textDecoration: 'none',
        alignSelf: 'center',
        '&:hover': {
            textDecoration: 'underline',
        },
        whiteSpace: 'nowrap',
    },
    policiesContainer: {
        alignSelf: 'flex-end',
        display: 'flex',
        paddingRight: '4rem',
    },
    policiesLink: {
        composes: '$link',
        paddingRight: '2.5rem',
    },
    mailIcon: {
        width: '2rem',
        height: '2rem',
        paddingRight: '0.5rem',
    },
    contactLink: {
        composes: '$link',
        ...typography.regularNone,
        alignItems: 'center',
    },
}));
