import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { KowieDesignTheme } from '../../Config/KowieDesignTheme';

export const TermsOfService = () => {
    const style = useStyles();

    return (
        <div className={style.container}>
            <h1>Terms of Service</h1>
            <p>Effective date: July 29, 2023</p>
            <p>
                We may update our Terms of Service from time to time. Thus, you are advised to review this page
                periodically for any changes. We will notify you of any changes by posting the new Terms of Service on
                this page.
            </p>
            <h2>Terms</h2>
            <p>
                By accessing our apps, you are agreeing to be bound by these terms of service, all applicable laws and
                regulations, and agree that you are responsible for compliance with any applicable local laws. If you do
                not agree with any of these terms, you are prohibited from using or accessing KowieDesign apps. The
                materials contained in the apps are protected by applicable copyright and trademark law.
            </p>
            <h2>Use License</h2>
            <ol>
                <li>
                    Permission is granted to temporarily download one copy of each KowieDesign app per device for
                    personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of
                    title, and under this license you may not:
                    <ol type="a">
                        <li>modify or copy the materials;</li>
                        <li>
                            use the materials for any commercial purpose, or for any public display (commercial or
                            non-commercial);
                        </li>
                        <li>attempt to decompile or reverse engineer any software contained in KowieDesign apps;</li>
                        <li>remove any copyright or other proprietary notations from the materials; or</li>
                        <li>
                            transfer the materials to another person or &quot;mirror&quot; the materials on any other
                            server.
                        </li>
                    </ol>
                </li>
                <li>
                    This license shall automatically terminate if you violate any of these restrictions and may be
                    terminated by KowieDesign at any time. Upon terminating your viewing of these materials or upon the
                    termination of this license, you must destroy any downloaded materials in your possession whether in
                    electronic or printed format.
                </li>
            </ol>
            <h2>Disclaimer</h2>
            <p>
                The materials within KowieDesign apps are provided on an &apos;as is&apos; basis. KowieDesign makes no
                warranties, expressed or implied, and hereby disclaims and negates all other warranties including,
                without limitation, implied warranties or conditions of merchantability, fitness for a particular
                purpose, or non-infringement of intellectual property or other violation of rights.
            </p>
            <p>
                Further, KowieDesign does not warrant or make any representations concerning the accuracy, likely
                results, or reliability of the use of the materials on its website or otherwise relating to such
                materials or on any sites linked to KowieDesign.
            </p>
            <h2>Limitations</h2>
            <p>
                In no event shall KowieDesign or its suppliers be liable for any damages (including, without limitation,
                damages for loss of data or profit, or due to business interruption) arising out of the use or inability
                to use KowieDesign apps, even if KowieDesign or a KowieDesign authorized representative has been
                notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow
                limitations on implied warranties, or limitations of liability for consequential or incidental damages,
                these limitations may not apply to you.
            </p>
            <h2>Accuracy of materials</h2>
            <p>
                The materials appearing in KowieDesign apps could include technical, typographical, or photographic
                errors. KowieDesign does not warrant that any of the materials on KowieDesign apps are accurate,
                complete or current. KowieDesign may make changes to the materials contained in KowieDesign apps at any
                time without notice. However KowieDesign does not make any commitment to update the materials.
            </p>
            <h2>Third-Party Services</h2>
            <p>KowieDesign apps use third-party services that declare their Terms and Conditions.</p>
            <p>Links to Terms and Conditions of third-party service providers used by the apps:</p>
            <ul>
                <li>
                    <Link to="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
                        Google Play Services
                    </Link>
                </li>
                <li>
                    <Link to="https://expo.io/terms" target="_blank" rel="noopener noreferrer">
                        Expo
                    </Link>
                </li>
                <li>
                    <Link to="https://amplitude.com/terms" target="_blank" rel="noopener noreferrer">
                        Amplitude
                    </Link>
                </li>
                <li>
                    <Link to="https://www.revenuecat.com/terms" target="_blank" rel="noopener noreferrer">
                        RevenueCat
                    </Link>
                </li>
            </ul>
            <h2>Links</h2>
            <p>
                KowieDesign has not reviewed all of the sites linked to its app and is not responsible for the contents
                of any such linked site. The inclusion of any link does not imply endorsement by KowieDesign of the
                site. Use of any such linked website is at the user&apos;s own risk.
            </p>
            <h2>Modifications</h2>
            <p>
                KowieDesign may revise these terms of service for its app at any time without notice. By using
                KowieDesign apps you are agreeing to be bound by the then current version of these terms of service.
            </p>
            <h2>Governing Law</h2>
            <p>
                These terms and conditions are governed by and construed in accordance with the laws of California and
                you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
            </p>
            <h2>Contact Us</h2>
            <p>
                {
                    'If you have any questions or suggestions about our Terms of Service, do not hesitate to contact us at '
                }
                <Link
                    to="mailto:whatsfordin.contact@gmail.com?subject=What%27s%20For%20Din%3F"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    whatsfordin.contact@gmail.com
                </Link>
                .
            </p>
        </div>
    );
};

const useStyles = createUseStyles((theme: KowieDesignTheme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '3.5rem 10rem',
        background: theme.background.static,
        width: '100vw',
    },
}));
