import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { KowieDesignTheme } from '../../Config/KowieDesignTheme';

export const PrivacyPolicy = () => {
    const style = useStyles();

    return (
        <div className={style.contentContainer}>
            <h1>Privacy Policy</h1>
            <p>Effective date: July 29, 2023</p>
            <p>
                KowieDesign (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the mobile application (the
                &quot;Service&quot;).
            </p>
            <p>
                This page informs you of our policies regarding the collection, use, and disclosure of personal data
                when you use our Service and the choices you have associated with that data.
            </p>
            <p>
                We use your data to provide and improve the Service. By using the Service, you agree to the collection
                and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy,
                terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
            </p>
            <h2>Information Collection and Use</h2>
            <p>
                For a better experience, while using our Service, we may ask you to provide us with certain personally
                identifiable information, including but not limited to email. The information that we request will be
                retained by us and used as described in this privacy policy.
            </p>
            <p>We use the collected data for various purposes, including but not limited to:</p>
            <ul>
                <li>To provide and maintain the Service</li>
                <li>To notify you about changes to our Service</li>
                <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                <li>To provide customer care and support</li>
                <li>To provide analysis or valuable information so that we can improve the Service</li>
                <li>To monitor the usage of the Service</li>
                <li>To detect, prevent and address technical issues</li>
            </ul>
            <p>The app does use third-party services that may collect information used to identify you.</p>
            <p>Links to the privacy policy of third-party service providers used by the app</p>
            <ul>
                <li>
                    <Link to="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">
                        Google Play Services
                    </Link>
                </li>
                <li>
                    <Link to="https://expo.io/privacy" target="_blank" rel="noopener noreferrer">
                        Expo
                    </Link>
                </li>
                <li>
                    <Link to="https://amplitude.com/privacy" target="_blank" rel="noopener noreferrer">
                        Amplitude
                    </Link>
                </li>
                <li>
                    <Link to="https://www.revenuecat.com/privacy" target="_blank" rel="noopener noreferrer">
                        RevenueCat
                    </Link>
                </li>
            </ul>
            <h2>Disclosure</h2>
            <p>The app does use third-party services that may collect information used to identify you.</p>
            <ul>
                <li>To comply with a legal obligation</li>
                <li>To protect and defend the rights or property of KowieDesign</li>
                <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>To protect the personal safety of users of the Service or the public</li>
                <li>To protect against legal liability</li>
            </ul>
            <h2>Log Data</h2>
            <p>
                We want to inform you that whenever you use our Service, in the case of an error in the app we collect
                data and information (through third-party products) on your phone called Log Data. This Log Data may
                include information such as your device Internet Protocol (“IP”) address, device name, operating system
                version, the configuration of the app when utilizing our Service, the time and date of your use of the
                Service, and other statistics.
            </p>
            <h2>Service Providers</h2>
            <p>We may employ third-party companies and individuals due to the following reasons:</p>
            <ul>
                <li>To facilitate our Service;</li>
                <li>To provide the Service on our behalf;</li>
                <li>To perform Service-related services; or</li>
                <li>To assist us in analyzing how our Service is used.</li>
            </ul>
            <p>
                We want to inform users of this Service that these third parties have access to their Personal
                Information. The reason is to perform the tasks assigned to them on our behalf. However, they are
                obligated not to disclose or use the information for any other purpose.
            </p>
            <h2>Security</h2>
            <p>
                We value your trust in providing us your Personal Information, thus we are striving to use commercially
                acceptable means of protecting it. But remember that no method of transmission over the internet, or
                method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
            </p>
            <h2>Links to Other Sites</h2>
            <p>
                This Service may contain links to other sites. If you click on a third-party link, you will be directed
                to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you
                to review the Privacy Policy of these websites. We have no control over and assume no responsibility for
                the content, privacy policies, or practices of any third-party sites or services.
            </p>
            <h2>Children&apos;s Privacy</h2>
            <p>
                These Services do not address anyone under the age of 13. We do not knowingly collect personally
                identifiable information from children under 13 years of age. In the case we discover that a child under
                13 has provided us with personal information, we immediately delete this from our servers. If you are a
                parent or guardian and you are aware that your child has provided us with personal information, please
                contact us so that we will be able to do the necessary actions.
            </p>
            <h2>Contact Us</h2>
            <p>
                {'If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at '}
                <Link
                    to="mailto:whatsfordin.contact@gmail.com?subject=What%27s%20For%20Din%3F"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    whatsfordin.contact@gmail.com
                </Link>
                .
            </p>
        </div>
    );
};

const useStyles = createUseStyles((theme: KowieDesignTheme) => ({
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '3.5rem 10rem',
        background: theme.background.static,
    },
}));
