import { createUseStyles } from 'react-jss';
import { Outlet, useLocation } from 'react-router';
import { Header } from '../Components/Header';
import { KowieDesignTheme } from '../Config/KowieDesignTheme';

export const Main = () => {
    const location = useLocation();
    const isPolicyPage = location.pathname === '/tos' || location.pathname === '/privacy';
    const style = useStyles();

    const contentContainerStyle = isPolicyPage ? style.contentContainerWithWhite : style.contentContainer;
    return (
        <div className={style.container}>
            <div className={style.headerContainer}>
                <div className={style.headerContent}>
                    <Header />
                </div>
            </div>
            <div className={contentContainerStyle}>
                <div className={style.content}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

const useStyles = createUseStyles((theme: KowieDesignTheme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'clip',
        background: theme.background.gradient,
    },
    headerContainer: {
        display: 'flex',
        width: '100%',
        height: '6rem',
    },
    headerContent: {
        display: 'flex',
        maxWidth: '90rem',
        minWidth: '40rem',
        width: '100%',
        height: '100%',
        margin: 'auto',
    },
    contentContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    contentContainerWithWhite: {
        composes: '$contentContainer',
        background: theme.background.static,
    },
    content: {
        display: 'flex',
        maxWidth: '90rem',
        minWidth: '40rem',
    },
}));
